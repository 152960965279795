body {
    font-family: 'Karla', sans-serif;
    background-image: url("../images/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    padding-bottom: 75px;

    @media (min-width: 768px) {
        padding-bottom: 110px;
    }
}
.navbar-brand {
    margin-right: 0;

    img {
        height: 21px;
    }
}
.navbar-dark {
    padding-top: 35px;
    padding-bottom: 35px;
    .navbar-text {
        color: #ffffff;
        opacity: 1;
        padding: 0 20px;
        border-right: 1px solid #ffffff;
        font-size: 18px;
        font-weight: bold;
        text-align: center;

        &:last-child {
            border-right: 0;
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 0;
    }
}
.page-title {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 6px;

    @media (max-width: 767px) {
        font-size: 35px;
        margin-bottom: 14px;  
    }
}
.page-description {
    max-width: 465px;
    font-size: 18px;
    margin-bottom: 59px;

    @media (max-width: 767px) {
        font-size: 14px;   
    }
}

p {
    font-size: 14px;
    margin-bottom: 21px;
}

.footer-social-links {
    .social-link {
        display: inline-block;
        text-align: center;
        line-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ffffff;
        color: #000000;
        margin-right: 16px;
        transition: all 0.3s ease-in-out;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            text-decoration: none;
            background-color: #000000;
            color: #ffffff;
        }
    }
}